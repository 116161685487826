import styled from "styled-components";
import { Announcement } from "../components/Announcement";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Newsletter } from "../components/Newsletter";
import { mobile } from "../responsive";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  ${mobile({ padding: "10px", flexDirection:"column" })}
`;

const ImgContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 100%;
  height: 90vh;
  object-fit: cover;
  ${mobile({ height: "40vh" })}
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 200;
  ${mobile({ textAlign: "center" })}
`;

const Subitle = styled.h4`
  font-weight: 200;
  ${mobile({ textAlign: "center" })}
`;

const Desc = styled.p`
  margin: 20px 0px;
  text-align: justify;
`;

export const About = () => {

  return (
    <Container>
      <Navbar />
      <Announcement />
      <Wrapper>
        <ImgContainer>
          <Image src="/img/slider/logog.jpg" />
        </ImgContainer>
        <InfoContainer>
          <Title>Nosotros</Title>
          <br />
          <Subitle><strong>Misión Frutéria</strong></Subitle>
          <Desc>
            Queremos conservar la esencia de cada fruta a través de diferentes 
            procesos para brindar lo mejor de nuestros productos, manteniendo los mejores 
            estándares creciendo continuamente mediante la calidad y la innovación.
          </Desc>
          <br />
          <Subitle><strong>Visión Frutéria</strong></Subitle>
          <Desc>
            Ser la marca mexicana más reconocido en los mercados nacional e internacional, 
            siendo proveedor líder de frutos frescos y procesados.
          </Desc>
          <br />
          <Subitle><strong>Misión Bioritec</strong></Subitle>
          <Desc>
            Ser una empresa social y éticamente responsable y reconocida a nivel nacional.
          </Desc>
          <br />
          <Subitle><strong>Visión Bioritec</strong></Subitle>
          <Desc>
            El distinguirnos por ser la mejor opción para el campo del país ofreciendo los mejores precios 
            con productos de calidad.
          </Desc>
          <br />
          <Subitle><strong>¿Qué es el liofilizado?</strong></Subitle>
          <Desc>
            La liofilización es una tecnica usada desde hace años para la conservación de alimentos perecederos 
            preservando al maximo su sabor, aroma y propiedades. La fruta conserva una apariencia parecida a su 
            estado natural, pero está completamente libre de agua, es decir es ligera y crujiente.
          </Desc>
          <br />
          <Subitle><strong>¿Cómo es el proceso de liofilizado?</strong></Subitle>
          <Desc>
            El proceso de liofilizado consiste en congelar los alimentos a muy baja tempratura hiper congelación a 
            temperaturas inferiores a -60°, y una vez congeladas se baja bruscamente la presión para conseguir la 
            sublimación de agua, es decir, pasa de hielo a vapor, sin pasar por un estado líquido.
          </Desc>
          <br />
          <Subitle><strong>Historia</strong></Subitle>
          <Desc>
              En el año 2014 comenzamos con la venta de frutos rojos frescos en las calles de Colima, 
              con el paso de los años nos comenzaron a interesar diferentes procesos y los productos 
              que se hacían con la fruta para su conservación y este es el resultado.
              <br /><br />
              En 2021 Frutéria llegó para quedarse, una manera de comer fruta fresca y a la vez una manera 
              muy diferente e innovadora que aportan exactamente lo mismo.
          </Desc>
          <br />
          <Title>
              <strong>¡Somos La Nueva Generación de Frutas!</strong>
          </Title>
        </InfoContainer>
      </Wrapper>
      <Newsletter />
      <Footer />
    </Container>
  );
  
};

//export default About;