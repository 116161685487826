import styled from "styled-components";

const Container = styled.div`
  height: 30px;
  background-color: #fcf5f5;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 50px;
`;

const Title = styled.h1`
  font-size: 70px;
  margin-bottom: 20px;
  color: #eff0f0;
  text-shadow: 2px 2px 2px #b0b6b4;
`;

export const Dividersf = () => {

  return <Container>
      <Title>Frutéria</Title>
    </Container>
    
};

//export default Dividersf;