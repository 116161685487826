import styled from "styled-components";
import { Productfu } from "./Productfu";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
`;

const Logo = styled.h1``;

export const Productsfu = () => {

  //const URL = "http://localhost/rest/consultas_ultimos_f.php";
  const URL = "http://govallsa.com/rest/consultas_ultimos_f.php";
  
  const [ products, setProducts ] = useState([]);

  /*
  useEffect(() => {
      fetch(URL)
        .then((result) => result.json())
        .then((data) => {
          setProducts(data);
        });
    }, []);
    */

    useEffect(() => {
      const getProduct = async () => {
        try {
          const response = await fetch(URL);
          const datos = await response.json();
          //setProduct(response.data);
          setProducts(datos);
          //console.log(datos);
          //console.log(datos[0].nombre);
        } catch (err) {console.log(err);}
      };
      getProduct();
    }, []);

  return (
    <>
      <Container>
        {products.map((item) => (
          <Productfu item={item} key={item.clave} />
        ))}
      </Container>
      <center><Link to = "/productlistf"><Button>VER MÁS</Button></Link></center>
      <br/><br/><br/>
    </>
  );
  
};

//export default Productsfu;