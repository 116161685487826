import styled from "styled-components";
import { Navbar } from "../components/Navbar";
import { Announcement } from "../components/Announcement";
import { Newsletter } from "../components/Newsletter";
import { Footer } from "../components/Footer";
import { mobile } from "../responsive";
import { Productsbt } from "../components/Productsbt";
import { useState } from "react";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  margin: 20px;
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  ${mobile({ marginRight: "0px" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  ${mobile({ margin: "10px 0px" })}
`;
const Option = styled.option``;

export const ProductListb = () => {

  const [filters, setFilters] = useState({});
  //const [sort, setSort] = useState("nuevo");

  const handleFilters = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
  };

  return (
    <Container>
      <Navbar />
      <Announcement />
      <Title>Bioritec</Title>
      <FilterContainer>
        <Filter>
          <FilterText>Filter Products:</FilterText>
          <Select name="id_categoria" onChange={handleFilters}>
            <Option disabled>
              Categoria
            </Option>
            <Option value="1">Sistemas de Riego</Option>
            <Option value="2">Cañones</Option>
          </Select>
          {/*<Select>
            <Option disabled selected>
              Subcategoria
            </Option>
            <Option>Mangueras</Option>
            <Option>Cintillas de Riego</Option>
            <Option>Mangueras Planas</Option>
            <Option>Aspersores</Option>
            <Option>Micro Aspersores</Option>
            <Option>Conectores y valvulas</Option>
            <Option>Goteros y perforadoras</Option>
            <Option>Filtros</Option>
            <Option>PVC</Option>
            <Option>Tubin</Option>
            <Option>Cañones</Option>
          </Select>*/}
        </Filter>
        {/*<Filter>
          <FilterText>Sort Products:</FilterText>
          <Select>
            <Option selected>Newest</Option>
            <Option>Price (asc)</Option>
            <Option>Price (desc)</Option>
          </Select>
        </Filter>*/}
      </FilterContainer>
      {/*<Productsbt />*/}
      <Productsbt filters={filters} />
      <Newsletter />
      <Footer />
    </Container>
  );

};

//export default ProductListb;