import { css } from "styled-components";

export const mobile = (props) => {
  return css`
    @media only screen and (max-width: 560px) {
      ${props}
    }
  `;
};

/*
Código Original
export const mobile = (props) => {
  return css`
    @media only screen and (max-width: 380px) {
      ${props}
    }
  `;
};


@media (max-width: 560px) {
  .pelisGrid{
      grid-template-columns: 100%;
  }
}
*/