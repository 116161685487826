import styled from "styled-components";
import { Productft } from "./Productft";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./pagination.css";

const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
`;

export const Productsft = ({filters}) => {

  //console.log("filtro: ", filters);
  //console.log(Object.values(filters));
  //const URL = "http://localhost/rest/consultasf.php";
  const URL = "http://govallsa.com/rest/consultasf.php";

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  /*
  console.log("products ", products);                 //array(21)
  console.log("filteredProducts ", filteredProducts); //array(21)
  console.log("pageNumber ", pageNumber);             //0
  console.log("productsPerPage ", productsPerPage);   //10
  console.log("pagesVisited ", pagesVisited);         //0
  console.log("pageCount ", pageCount);               //3
  */
 
   useEffect(() => {
    const getProducts = async () => {
      try {
        fetch(URL)
          .then((result) => result.json())
          .then((data) => {
            setProducts(data);
          });
        } catch (err) {console.log(err);}
    };
      getProducts();
  }, []);

  useEffect(() => {
    setFilteredProducts(
      products.filter((item) =>
        Object.entries(filters).every(([key, value]) =>
          item[key].includes(value)
        )
      )
    );
  }, [filters, products]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  
  return (
    <div>
      <Container>
        {filteredProducts.slice(pagesVisited, pagesVisited + productsPerPage).map((item) => (
          <Productft item={item} key={item.clave} />
        ))}
        <br/>
      </Container>
      <br/>
      <ReactPaginate
        previousLabel={"< Anterior"}
        nextLabel={"Siguiente >"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
      <br/><br/>
    </div>
  );
  
};

//export default Productsft;