import styled from "styled-components";
import { Announcement } from "../components/Announcement";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Newsletter } from "../components/Newsletter";
import { mobile } from "../responsive";
import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Pinterest,
  Room,
  Twitter,
  WhatsApp,
} from "@material-ui/icons";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  ${mobile({ padding: "10px", flexDirection:"column" })}
`;

const ImgContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 100%;
  height: 90vh;
  object-fit: cover;
  ${mobile({ height: "40vh" })}
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 200;
  ${mobile({ textAlign: "center" })}
`;

const Desc = styled.p`
  margin: 20px 0px;
  text-align: justify;
`;

const SocialIcon = styled.div`
  width: 300px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const Contact = () => {

  return (
    <Container>
      <Navbar />
      <Announcement />
      <Wrapper>
        <ImgContainer>
          <Image src="/img/mapa.jpg" />
        </ImgContainer>
        <InfoContainer>
          <Title>Contacto</Title>
          <br />
          <Desc>
            <Room style={{marginRight:"10px"}}/>Colima, Colima México
          </Desc>
          <br />
          <Desc>
            <MailOutline style={{marginRight:"10px"}} />fruteria@govallsa.com
          </Desc>
          <Desc>
            <MailOutline style={{marginRight:"10px"}} />grupogovallsa@gmail.com
          </Desc>
          <br />
          <Desc>
            <Phone style={{marginRight:"10px"}}/>+52 312 207 7777
          </Desc>
          <Desc>
            <WhatsApp style={{marginRight:"10px"}}/>+52 312 102 1272
          </Desc>
          <br />

              <a href="https://www.instagram.com/fru.teriamx/" target="_blank" rel="noreferrer">
                <SocialIcon>
                  <Instagram style={{marginRight:"10px"}} />fru.teriamx
                </SocialIcon>
              </a>


            <a href="https://www.facebook.com/Frut%C3%A9ria-Comfort-Food-103096125616361/" target="_blank" rel="noreferrer">
              <SocialIcon>
                <Facebook style={{marginRight:"10px"}} />Frutēria Comfort Food
              </SocialIcon>
            </a>

          <br />
        </InfoContainer>
      </Wrapper>
      <Newsletter />
      <Footer />
    </Container>
  );

};

//export default Contact;