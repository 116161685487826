import {
    Facebook,
    Instagram,
    MailOutline,
    Phone,
    Pinterest,
    Room,
    WhatsApp,
    Twitter,
  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../responsive";
  
  const Container = styled.div`
    display: flex;
    ${mobile({ padding: "0px", flexDirection:"column" })}
  `;
  
  const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
  `;
  
  const Logo = styled.h1`
  ${mobile({ textAlign: "center" })}
  `;
  
  const Desc = styled.p`
    margin: 20px 0px;
    text-align: justify;
  `;
  
  const SocialContainer = styled.div`
    display: flex;
  `;
  
  const SocialIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  `;
  
  const Center = styled.div`
    flex: 1;
    padding: 20px;
    ${mobile({ display: "none" })}
  `;
  
  const Title = styled.h3`
    margin-bottom: 30px;
  `;
  
  const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  `;
  
  const ListItem = styled.li`
    width: 50%;
    margin-bottom: 10px;
  `;
  
  const Right = styled.div`
    flex: 1;
    padding: 20px;
    ${mobile({ backgroundColor: "#fff8f8"})}
  `;
  
  const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  `;
  
  const Payment = styled.img`
      width: 50%;
  `;

  export const Footer = () => {

    return (
      <Container>
        <Left>
          <Logo>GOVALLSA</Logo>
          <Desc>
            Somos una empresa familiar comprometida con el campo y los consumidores finales, buscando desde nuestros 
            inicios el mejorar las condiciones del agricultor desde la primera semilla que se siembra hasta el último 
            día de cosecha y también con nuestros consumidores ofreciéndoles productos saludables y de alta calidad. 
            Comercializamos e importamos sistemas de riego y procesamos frutos y alimentos saludables. 
            ¡Te invitamos a conocernos!
          </Desc>
          <SocialContainer>
            <a href="https://www.facebook.com/Frut%C3%A9ria-Comfort-Food-103096125616361/" target="_blank" rel="noreferrer">
              <SocialIcon color="3B5999"> 
                <Facebook />
              </SocialIcon>
            </a>
            <a href="https://www.instagram.com/fru.teriamx/" target="_blank" rel="noreferrer">
              <SocialIcon color="E4405F">
                <Instagram />
              </SocialIcon>
            </a>
            {/*<SocialIcon color="55ACEE">
              <Twitter />
            </SocialIcon>
            <SocialIcon color="E60023">
              <Pinterest />
            </SocialIcon>*/}
          </SocialContainer>
        </Left>
        <Center>
          <Title>Descubrir</Title>
          <List>
            <ListItem>
              <Link to="/">Home</Link>
            </ListItem>
            <ListItem>
              <Link to="/productlistf">Fruteria</Link>
            </ListItem>
            <ListItem>
              <Link to="/productlistb">Bioritec</Link>
            </ListItem>
            <ListItem>
              <Link to="/about">Nosotros</Link>
            </ListItem>
            <ListItem>
              <Link to="/contact">Contacto</Link>
            </ListItem>
          </List>
        </Center>
        <Right>
          <Title>Contacto</Title>
          <ContactItem>
            <Room style={{marginRight:"10px"}}/>Colima, Colima México
          </ContactItem>
          <ContactItem>
            <Phone style={{marginRight:"10px"}}/>+52 312 207 7777
          </ContactItem>
          <ContactItem>
            <WhatsApp style={{marginRight:"10px"}}/>+52 312 102 1272
          </ContactItem>
          <ContactItem>
            <MailOutline style={{marginRight:"10px"}}/>fruteria@govallsa.com
          </ContactItem>
          <ContactItem>
            <MailOutline style={{marginRight:"10px"}}/>grupogovallsa@gmail.com
          </ContactItem>
          {/*<Payment src="https://i.ibb.co/Qfvn4z6/payment.png" />*/}
        </Right>
      </Container>
    );
    
  };
  
  //export default Footer;