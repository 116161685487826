import styled from "styled-components";
import { Navbar } from "../components/Navbar";
import { Announcement } from "../components/Announcement";
import { Newsletter } from "../components/Newsletter";
import { Footer } from "../components/Footer";
import { mobile } from "../responsive";
import { Productsft } from "../components/Productsft";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  margin: 20px;
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  ${mobile({ marginRight: "0px" })}
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 20px;
  ${mobile({ margin: "10px 0px" })}
`;
const Option = styled.option``;

const Input = styled.input`
  border: none;
  flex: 8;
  padding-left: 20px;
`;

export const ProductListf = () => {

  //const location = useLocation();
  //const cat = location.pathname.split("/")[1];
  const [filters, setFilters] = useState({});
  //const [sort, setSort] = useState("nuevo");

  const handleFilters = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
  };

  //console.log(filters);

  return (
    <Container>
      <Navbar />
      <Announcement />
      <Title>Frutéria</Title>
      <FilterContainer>
        <Filter>
          <FilterText>Filtro Productos:</FilterText>
          <Select name="id_categoria" onChange={handleFilters}>
            <Option disabled>
              Categoria
            </Option>
            <Option value="1">Fruta fresca</Option>
            <Option value="2">Deshidratado</Option>
            <Option value="3">Liofilizado</Option>
            <Option value="4">Bebidas</Option>
          </Select>
          {/*<Select>
            <Option disabled selected>
              Size
            </Option>
            <Option>XS</Option>
            <Option>S</Option>
            <Option>M</Option>
            <Option>L</Option>
            <Option>XL</Option>
          </Select>*/}
        </Filter>
        {/*<Filter>
          <FilterText>Sort Products:</FilterText>
          <Select>
            <Option selected>Newest</Option>
            <Option>Price (asc)</Option>
            <Option>Price (desc)</Option>
          </Select>
        </Filter>*/}
      </FilterContainer>
      {/*<Productsft />*/}
      <Productsft filters={filters} />
      <Newsletter />
      <Footer />
    </Container>
  );

};

//export default ProductListf;