export const sliderItems = [
    {
	  "id": 1,
      "img": "/img/slider/logog.jpg",
	  "title": "GOVALLSA",
	  "desc": "Somos una empresa familiar comprometida con el campo y los consumidores finales, buscando desde nuestros inicios el mejorar las condiciones del agricultor desde la primera semilla que se siembra hasta el último día de cosecha y también con nuestros consumidores ofreciéndoles productos saludables y de alta calidad. Comercializamos e importamos sistemas de riego y procesamos frutos y alimentos saludables. ¡Te invitamos a conocernos!",
	  "bg": "f5fafd"
    },
    {
	  "id": 2,
      "img": "/img/slider/logob.jpg",
	  "title": "BIORITEC",
	  "desc": "El apoyo al agricultor siempre será lo más importante, te invitamos a conocer Bioritec, sistemas de irrigación con la mejor calidad y el mejor precio del país, ¡compruébalo!",
	  "bg": "f5fafd"
    },
    {
	  "id": 3,
      "img": "/img/slider/logof.jpg",
	  "title": "FRUTÉRIA",
	  "desc": "La nueva generación de frutas llegó para quedarse! Te invitamos a que conozcas nuestros diferentes productos saludables, libres de azúcares y conservadores. ¡Orgullosamente Mexicanos con calidad mundial!",
	  "bg": "f5fafd"
    }
]

export const categories =[
    {
	  "id": 1,
      "img": "/img/categorias/fruta_1.jpg",
	  "title": ""
    },
    {
	  "id": 2,
      "img": "/img/categorias/fruta_2.jpg",
	  "title": ""
    },
    {
	  "id": 3,
      "img": "/img/categorias/riego.jpg",
	  "title": ""
    }
]