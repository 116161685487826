import React from 'react';
import styled from "styled-components";
import { Announcement } from '../components/Announcement';
import { Navbar } from '../components/Navbar';
import { Slider } from '../components/Slider';
import { Categories } from '../components/Categories';
import { Productsfu } from '../components/Productsfu';
import { Newsletter } from '../components/Newsletter';
import { Footer } from '../components/Footer';
import { Dividersf } from '../components/Dividersf';
import { Dividersb } from '../components/Dividersb';
import { Productsbu } from '../components/Productsbu';


const Container = styled.div``;

export const Home = () => {

    return (
        <Container>
            <Navbar />
            <Announcement />
            <Slider />
            <Categories />
            <Dividersf/>
            <Productsfu />
            <Dividersb/>
            <Productsbu />
            <Newsletter />
            <Footer />
        </Container>
    );
    
};

//export default Home;
